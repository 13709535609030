const SendIcon = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.56152 12.2133L24.1231 12.2133M5.38431 12.616L1.73397 20.9123C1.4124 21.6431 1.25161 22.0086 1.32223 22.2328C1.38344 22.4272 1.53561 22.5795 1.72993 22.641C1.9541 22.7119 2.31971 22.5515 3.05095 22.2308L23.8028 13.1291C24.4629 12.8396 24.793 12.6948 24.894 12.4924C24.9817 12.3167 24.9817 12.1099 24.894 11.9342C24.793 11.7318 24.4629 11.587 23.8028 11.2975L3.05095 2.19581C2.31971 1.87509 1.9541 1.71473 1.72993 1.78562C1.5356 1.84706 1.38345 1.9994 1.32223 2.1938C1.25161 2.41805 1.41239 2.78348 1.73397 3.51433L5.38431 11.8106C5.44959 11.9589 5.48223 12.0331 5.49512 12.1102C5.50655 12.1784 5.50655 12.2482 5.49512 12.3165C5.48223 12.3935 5.44959 12.4677 5.38431 12.616Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SendIcon;
