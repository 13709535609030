//KIA = Kempegowda International Airport
//ZOMALAND COORDINATES = Embassy International

export const COORDINATES = {
  KIA: { LAT: 13.199379, LNG: 77.710136 },
  EMBASSY_INTERNATIONAL: {
    LAT: 13.1737971,
    LNG: 77.5952568,
  },
  BANGALORE: { LAT: 12.972442, LNG: 77.580643 },
  MUMBAI: { LAT: 19.08607, LNG: 72.87636 },
  JIO_WORLD_DRIVE_MUMBAI: { LAT: 19.05356, LNG: 72.84923 },
};
