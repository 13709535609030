import React from "react";

const PlaneLanding = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSvgjs="http://svgjs.com/svgjs"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          fill="#fff"
          d="M20.5 19h-17c-.55 0-1 .45-1 1s.45 1 1 1h17c.55 0 1-.45 1-1s-.45-1-1-1zm1.57-9.36c-.22-.8-1.04-1.27-1.84-1.06L14.92 10 8.46 3.98c-.27-.26-.66-.35-1.02-.25-.68.19-1 .97-.65 1.58l3.44 5.96-4.97 1.33-1.57-1.24c-.25-.19-.57-.26-.88-.18l-.33.09c-.32.08-.47.45-.3.73l1.88 3.25c.23.39.69.58 1.12.47L21 11.48c.8-.22 1.28-1.04 1.07-1.84z"
          className="color000 svgShape"
        />
      </svg>
    </svg>
  );
};

export default PlaneLanding;
