const FromPoint = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="2.25" stroke="#D83F87" strokeWidth="1.5" />
      <circle cx="6" cy="6" r="5" stroke="#E5E5E5" />
    </svg>
  );
};

export default FromPoint;
