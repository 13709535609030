import React from "react";

const PlaneTakeoff = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsSvgjs="http://svgjs.com/svgjs"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          fill="#fff"
          d="M20.5 19h-17c-.55 0-1 .45-1 1s.45 1 1 1h17c.55 0 1-.45 1-1s-.45-1-1-1zM3.51 11.61l15.83 4.24c.8.21 1.62-.26 1.84-1.06.21-.8-.26-1.62-1.06-1.84l-5.31-1.42-2.58-8.45c-.11-.36-.39-.63-.75-.73-.68-.18-1.35.33-1.35 1.04v6.88L5.15 8.95 4.4 7.09c-.12-.29-.36-.51-.67-.59l-.33-.09c-.32-.09-.63.15-.63.48v3.75c0 .46.3.85.74.97z"
          className="color000 svgShape"
        />
      </svg>
    </svg>
  );
};

export default PlaneTakeoff;
