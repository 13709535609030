const ChatIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.67 19.9699C10.43 19.9899 10.19 19.9999 9.95 19.9999C8.78 19.9999 7.66999 19.7199 6.67999 19.2299L2.78999 19.7899L3.38 15.8799H3.39C2.99 14.9999 2.75 14.0299 2.75 12.9999C2.75 11.1099 3.51999 9.39989 4.75999 8.13989"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.86 11.78C11.3018 11.78 11.66 11.4308 11.66 11C11.66 10.5692 11.3018 10.22 10.86 10.22C10.4182 10.22 10.06 10.5692 10.06 11C10.06 11.4308 10.4182 11.78 10.86 11.78Z"
        fill="white"
      />
      <path
        d="M14.06 11.78C14.5018 11.78 14.86 11.4308 14.86 11C14.86 10.5692 14.5018 10.22 14.06 10.22C13.6182 10.22 13.26 10.5692 13.26 11C13.26 11.4308 13.6182 11.78 14.06 11.78Z"
        fill="white"
      />
      <path
        d="M17.25 11.78C17.6918 11.78 18.05 11.4308 18.05 11C18.05 10.5692 17.6918 10.22 17.25 10.22C16.8082 10.22 16.45 10.5692 16.45 11C16.45 11.4308 16.8082 11.78 17.25 11.78Z"
        fill="white"
      />
      <path
        d="M20.62 13.88L21.21 17.79L17.32 17.23C16.62 17.58 15.86 17.82 15.05 17.93C14.73 17.98 14.39 18 14.05 18C10.07 18 6.85999 14.87 6.85999 11C6.85999 9.07 7.64998 7.35 8.94998 6.08C10.24 4.79 12.05 4 14.06 4C18.04 4 21.25 7.13 21.25 11C21.25 12.03 21.01 13 20.61 13.88H20.62V13.88Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
